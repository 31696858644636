import { useToast } from '@/components/ui/use-toast';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

type Data = {
	message: any;
	errors: any;
};
type Error = {
	data: Data;
};

type Props = {
	error: any;
	name?: any;
	includesName?: string;
	isMainError?: boolean;
	isToast?: boolean;
};
const InputError = (props: Props) => {
	const { error, name, includesName, isMainError = false, isToast = false } = props;
	const [errorsMessages, setErrorMessages] = useState<any>([]);
	const { toast } = useToast();
	const t = useTranslations('common');
	const hasDotSeparatorNoSpaces = (str: string): boolean => {
		return str.includes('.') && !str.includes(' ');
	};
	useEffect(() => {
		if (typeof error?.data === 'object') {
			if (isMainError) {
				setErrorMessages([error.data.message || error.data.error]);
			} else {
				if (includesName && error.data.errors)
					setErrorMessages(
						Object.entries(error.data.errors)
							.filter(([key, val]) => key.includes(includesName))
							.map(([key, val]) => val)
					);
				else setErrorMessages(error.data.errors?.[name || ''] || error?.[name] || []);
			}
			if (isToast) {
				toast({
					title: error.data.message || error.data.error,
					variant: 'destructive',
				});
			}
		} else if (typeof error === 'string') {
			setErrorMessages([error]);
			if (isToast && error) {
				toast({
					title: error,
					variant: 'destructive',
				});
			}
		} else if (typeof error === 'undefined') {
			setErrorMessages([]);
		}
	}, [error]);
	return (
		<>
			{errorsMessages.length != 0 && !isToast && error?.length !== 0 && (
				<div className={'mt-1'}>
					{errorsMessages.map((item: any, index: number) => (
						<p key={index} className="flex items-center gap-1 text-xs text-red-600">
							{!isMainError && <div className="h-1.5 w-1.5 rounded-full bg-red-600"></div>}
							<span>{hasDotSeparatorNoSpaces(item) ? t(item) : item}</span>
						</p>
					))}
				</div>
			)}
		</>
	);
};
export default InputError;
