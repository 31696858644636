import { SVGProps } from 'react';

const Profile = (props: SVGProps<SVGSVGElement>) => (
	<svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_9696_66671)">
			<path
				d="M16.6945 17.4042C15.6835 16.7871 15.3207 16.8493 15.3207 15.6165C15.3207 15.185 15.3207 14.3833 15.3207 13.6437C15.8756 13.0274 16.0774 13.1042 16.5467 10.6848C17.4955 10.6848 17.4341 9.45201 17.8816 8.35845C18.2464 7.46792 17.9408 7.10689 17.6336 6.95933C17.8847 3.71412 17.8847 1.47906 14.2013 0.785698C12.8662 -0.358687 10.8515 0.0298383 9.89675 0.220805C8.94285 0.411772 8.08235 0.220805 8.08235 0.220805L8.24622 1.7584C6.84934 3.43477 7.54788 5.66983 7.32298 6.97891C7.02991 7.13858 6.76555 7.50669 7.11453 8.35845C7.56204 9.45201 7.50056 10.6848 8.44949 10.6848C8.91873 13.1042 9.12048 13.0274 9.67542 13.6437C9.67542 14.3833 9.67542 15.185 9.67542 15.6165C9.67542 16.8493 9.25642 16.8705 8.30173 17.4042C5.99998 18.6907 3.00051 19.4382 2.46311 23.4593C2.36467 24.1948 6.58753 24.9977 12.4981 25.0002C18.4086 24.9977 22.6313 24.1948 22.5331 23.4593C21.9957 19.4382 18.9687 18.7916 16.6945 17.4042Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_9696_66671">
				<rect width={25} height={25} fill="white" transform="translate(0 0.000244141)" />
			</clipPath>
		</defs>
	</svg>
);
export default Profile;
