import clsx from 'clsx';

type Props = {
	text: string;
	required?: boolean;
	customClass?: string;
	topInfoText?: string;
	labelCustomClass?: string;
	textClass?: string;
};

const Label = (props: Props) => {
	return (
		<div
			className={clsx(
				'flex',
				props.topInfoText && `flex-1 flex-grow ${props.labelCustomClass ?? ''}`,
				'justify-between',
				props.textClass
			)}
		>
			<label className={clsx('flex gap-1 text-[14px] font-semibold text-[#016DEA]', props.customClass)}>
				{props.text}
				{props.required && <span className="text-[#FA0101]">*</span>}
			</label>
			{props.topInfoText && <span className="text-xs font-medium leading-3 text-[#AAA9AD]">{props.topInfoText}</span>}
		</div>
	);
};

export default Label;
