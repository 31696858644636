'use client';
import TrashOutline from '@/components/icons/TrashOutline';
import clsx from 'clsx';
import Heading from './Heading';

type Props = {
	title?: string;
	buttonLabel?: string;
	children?: React.ReactNode;
	isDelete?: boolean;
	onDelete?: () => void;
	customClass?: string;
	innerCustomClass?: string;
	borderType?: number;
	hoverBorderType?: number;
	mainClass?: string;
	boxShadowStyle?: string;
};

const Card = (props: Props) => {
	const {
		title,
		buttonLabel,
		children,
		isDelete,
		onDelete,
		customClass,
		innerCustomClass,
		borderType,
		hoverBorderType,
		mainClass,
		boxShadowStyle,
	} = props;
	return (
		<>
			<div
				className={clsx(
					'inline-flex w-full rounded-[10px] p-px',
					borderType == 0 && 'bg-[linear-gradient(90deg,#FA0101_0%,#016DEA_100%)]',
					borderType == 1 && 'bg-[linear-gradient(90deg,#016DEA_0%,#FA0101_100%)]',
					hoverBorderType == 0 && 'hover:bg-[linear-gradient(90deg,#FA0101_0%,#016DEA_100%)]',
					hoverBorderType == 1 && 'hover:bg-[linear-gradient(90deg,#016DEA_0%,#FA0101_100%)]',
					mainClass
				)}
			>
				<div
					className={clsx(
						'custom-shadow relative flex w-full flex-col items-start gap-[15px] rounded-[10px] border border-[#E5E5E5] bg-white p-[20px]',
						customClass
					)}
					style={{ boxShadow: boxShadowStyle || '0px 0px 1px 0px #00000040' }}
				>
					{isDelete && (
						<div
							onClick={() => {
								if (onDelete) {
									onDelete();
								}
							}}
							className={'absolute -top-3 right-0 cursor-pointer rounded-full border bg-white p-1'}
						>
							<TrashOutline width={24} height={24} />
						</div>
					)}
					{title && <Heading title={title} buttonLabel={buttonLabel} />}

					{children && <div className={clsx('w-full', innerCustomClass)}>{children}</div>}
				</div>
			</div>
		</>
	);
};

export default Card;
