type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Consultations = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
			<g clipPath="url(#clip0_55_61200)">
				<path
					d="M13.5482 0H11.451C5.68718 0 0.998047 4.68914 0.998047 10.4529V10.8758V14.0133V16.0969C0.998047 16.4205 1.26033 16.6826 1.58376 16.6826H1.81805H2.46293V17.0951C2.46293 17.4186 2.72521 17.6808 3.04864 17.6808H4.1116C5.1625 17.6808 6.01741 16.8259 6.01741 15.775V11.1976C6.01741 10.1467 5.1625 9.29182 4.1116 9.29182H3.04864C2.72521 9.29182 2.46293 9.55399 2.46293 9.87754V10.2901H2.17358C2.26109 5.24744 6.3877 1.17143 11.451 1.17143H13.5482C18.6114 1.17143 22.7381 5.24744 22.8256 10.2902H22.5363V9.87754C22.5363 9.55399 22.274 9.29182 21.9506 9.29182H20.8877C19.8368 9.29182 18.9819 10.1467 18.9819 11.1976V15.7752C18.9819 16.8261 19.8368 17.681 20.8877 17.681H21.9506C22.1128 17.681 22.2596 17.615 22.3656 17.5084V18.7726C22.3656 20.4682 20.9861 21.8476 19.2906 21.8476H15.808C15.5755 21.2733 15.0127 20.8665 14.3559 20.8665H12.7745C11.9105 20.8665 11.2077 21.5694 11.2077 22.4333C11.2077 23.2973 11.9105 24.0001 12.7745 24.0001H14.3559C15.0127 24.0001 15.5755 23.5934 15.808 23.019H19.2906C21.632 23.019 23.537 21.1141 23.537 18.7726V16.6699C23.8021 16.6139 24.0012 16.3789 24.0012 16.0972V14.0135V10.876V10.4531C24.0012 4.68914 19.312 0 13.5482 0Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_55_61200">
					<rect width="24" height="24" fill={color} transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default Consultations;
