import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const SvgComponent = (props: Props) => {
	const { width = 17, height = 16 } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
			<path
				d="M3.5 6H5.5H21.5"
				stroke="url(#paint0_linear_492_86830)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.5 6V20C19.5 20.5304 19.2893 21.0391 18.9142 21.4142C18.5391 21.7893 18.0304 22 17.5 22H7.5C6.96957 22 6.46086 21.7893 6.08579 21.4142C5.71071 21.0391 5.5 20.5304 5.5 20V6M8.5 6V4C8.5 3.46957 8.71071 2.96086 9.08579 2.58579C9.46086 2.21071 9.96957 2 10.5 2H14.5C15.0304 2 15.5391 2.21071 15.9142 2.58579C16.2893 2.96086 16.5 3.46957 16.5 4V6"
				stroke="url(#paint1_linear_492_86830)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5 11V17"
				stroke="url(#paint2_linear_492_86830)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5 11V17"
				stroke="url(#paint3_linear_492_86830)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_492_86830"
					x1="10.5417"
					y1="6.95076"
					x2="10.7497"
					y2="4.99942"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_492_86830"
					x1="10.9769"
					y1="21.0151"
					x2="23.6842"
					y2="16.3796"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_492_86830"
					x1="10.8912"
					y1="16.7045"
					x2="11.912"
					y2="16.6159"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_492_86830"
					x1="14.8912"
					y1="16.7045"
					x2="15.912"
					y2="16.6159"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default SvgComponent;
