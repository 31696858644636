import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Entity = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
			<path
				d="M22.8516 23.4375H21.875V1.17188C21.875 0.524658 21.3503 0 20.7031 0H4.29688C3.64966 0 3.125 0.524658 3.125 1.17188V23.4375H2.14844C1.82485 23.4375 1.5625 23.6999 1.5625 24.0234V25H23.4375V24.0234C23.4375 23.6999 23.1751 23.4375 22.8516 23.4375ZM7.8125 3.71094C7.8125 3.38735 8.07485 3.125 8.39844 3.125H10.3516C10.6751 3.125 10.9375 3.38735 10.9375 3.71094V5.66406C10.9375 5.98765 10.6751 6.25 10.3516 6.25H8.39844C8.07485 6.25 7.8125 5.98765 7.8125 5.66406V3.71094ZM7.8125 8.39844C7.8125 8.07485 8.07485 7.8125 8.39844 7.8125H10.3516C10.6751 7.8125 10.9375 8.07485 10.9375 8.39844V10.3516C10.9375 10.6751 10.6751 10.9375 10.3516 10.9375H8.39844C8.07485 10.9375 7.8125 10.6751 7.8125 10.3516V8.39844ZM10.3516 15.625H8.39844C8.07485 15.625 7.8125 15.3626 7.8125 15.0391V13.0859C7.8125 12.7624 8.07485 12.5 8.39844 12.5H10.3516C10.6751 12.5 10.9375 12.7624 10.9375 13.0859V15.0391C10.9375 15.3626 10.6751 15.625 10.3516 15.625ZM14.0625 23.4375H10.9375V19.3359C10.9375 19.0124 11.1999 18.75 11.5234 18.75H13.4766C13.8001 18.75 14.0625 19.0124 14.0625 19.3359V23.4375ZM17.1875 15.0391C17.1875 15.3626 16.9251 15.625 16.6016 15.625H14.6484C14.3249 15.625 14.0625 15.3626 14.0625 15.0391V13.0859C14.0625 12.7624 14.3249 12.5 14.6484 12.5H16.6016C16.9251 12.5 17.1875 12.7624 17.1875 13.0859V15.0391ZM17.1875 10.3516C17.1875 10.6751 16.9251 10.9375 16.6016 10.9375H14.6484C14.3249 10.9375 14.0625 10.6751 14.0625 10.3516V8.39844C14.0625 8.07485 14.3249 7.8125 14.6484 7.8125H16.6016C16.9251 7.8125 17.1875 8.07485 17.1875 8.39844V10.3516ZM17.1875 5.66406C17.1875 5.98765 16.9251 6.25 16.6016 6.25H14.6484C14.3249 6.25 14.0625 5.98765 14.0625 5.66406V3.71094C14.0625 3.38735 14.3249 3.125 14.6484 3.125H16.6016C16.9251 3.125 17.1875 3.38735 17.1875 3.71094V5.66406Z"
				fill="white"
			/>
		</svg>
	);
};
export default Entity;
