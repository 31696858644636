'use client';
import InputError from '@/components/form/InputError';
import Label from '@/components/form/Label';
import SorryModule from '@/components/SorryModule';
import { clsx } from 'clsx';
import { ReactNode, useEffect, useState } from 'react';

export type optionsType = {
	id: number;
	name: string;
	description?: string;
	value?: string | boolean;
	icon?: ReactNode;
};

type Props = {
	options: optionsType[];
	selectedValue?: optionsType | undefined;
	label?: string;
	className?: string;
	itemClassName?: string;
	required?: boolean;
	onChange?: (item: optionsType) => void;
	onClick?: (item: optionsType) => void;
	type?: 'small' | 'large';
	radioStyle?: 'first' | 'second';
	name?: string;
	iconCheck?: boolean;
	disable?: boolean;
	labelClassName?: string;
	mainDivClassName?: string;
	iconClass?: string;
	customClass?: string;
	error?: any;
	customLabelClass?: string;
	radioCustomClass?: string;
	isFlat?: boolean;
	tabName?: string;
	translateT?: (t: string) => string;
};

const RadioGroup = (props: Props) => {
	const {
		iconCheck,
		label,
		required,
		onChange,
		onClick,
		className,
		options = [],
		itemClassName,
		selectedValue,
		type = 'small',
		radioStyle = 'first',
		labelClassName,
		mainDivClassName,
		iconClass,
		customClass = 'mb-2.5',
		error,
		name,
		disable = false,
		customLabelClass = '',
		radioCustomClass = '',
		isFlat,
		tabName,
		translateT,
	} = props;
	const [selected, setSelected] = useState<optionsType | undefined>(selectedValue);

	useEffect(() => {
		setSelected(selectedValue);
	}, [selectedValue]);

	const handleSelect = (item: any) => {
		if (!disable) {
			onChange?.(item);
			setSelected(item);
		}
	};

	return (
		<div className={clsx(mainDivClassName)}>
			{label && <Label text={label} required={required} customClass={customClass} />}
			<div
				className={clsx('', className, {
					'flex flex-1 flex-grow flex-col gap-y-2.5 rounded-[10px] p-[14px]': type === 'large',
					'flex gap-4': type !== 'large',
				})}
			>
				{options.length > 0 ? (
					<>
						{options.map((item: optionsType, index: number) => (
							// <div key={index} className={clsx('flex', itemClassName)}>
							<label
								key={item.id}
								className={clsx(
									'flex cursor-pointer items-center gap-[10px] font-medium capitalize',
									{
										'flex-1 flex-row-reverse justify-between': type === 'large',
										'cursor-not-allowed': disable,
									},
									customLabelClass
								)}
							>
								<input
									name={`${item.id}_${item.name}_${tabName}`}
									checked={selected?.name === item.name}
									onChange={() => handleSelect(item)}
									onClick={() => onClick && onClick(item)}
									className={clsx(
										'h-4 w-4',
										{ hidden: item.icon && iconCheck, 'border-style-radio': radioStyle === 'second' },
										radioCustomClass
									)}
									type="radio"
								/>
								<div className={clsx('flex items-center', type === 'large' ? 'gap-2.5' : '')}>
									{item.icon && (
										<>
											<div
												className={clsx(
													'white flex h-[40px] w-[40px] items-center justify-center rounded-full border',
													selected?.name === item.name && iconCheck ? 'default-gradient' : 'bg-[#F4F4F4]',
													iconClass
												)}
												// className={clsx(
												// 	'whitespace-nowrap text-xs leading-5 sm:text-sm sm:leading-6', // Responsive text and leading
												// 	type === 'large' ? 'text-gray-800' : 'ml-1 text-gray-500 sm:ml-2.5', // Responsive margin
												// 	labelClassName
												// )}
											>
												{item.icon}
											</div>
										</>
									)}
									<div className="flex flex-col">
										<span
											className={clsx(
												'whitespace-nowrap text-sm !leading-[22px]',
												type === 'large' ? 'text-[#2B2525]' : 'ms-2.5 text-black/50', //py-[9px] removed
												labelClassName
											)}
										>
											<span className="normal-case">{translateT ? translateT(item.name) : item.name}</span>
										</span>
										{type === 'large' && (
											<span className="mt-[2px] max-w-[200px] text-xs leading-[12px] text-black/50 sm:max-w-none">
												{item.description}
											</span>
										)}
									</div>
								</div>
							</label>
							// </div>
						))}
					</>
				) : (
					<div className="col-span-full flex w-full justify-center">
						<SorryModule />
					</div>
				)}
			</div>
			<InputError error={error} name={name} />
		</div>
	);
};

export default RadioGroup;
