'use Client';

import Label from '@/components/form/Label';
import { CreateRandomID } from '@/utils/common';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

type Props = {
	label?: string;
	checked?: boolean;
	onChange?: (value: boolean) => void;
	required?: boolean;
	title?: string;
	titleClassName?: string;
	disabled?: boolean;
	readOnly?: boolean;
	onKeyDown?: (event: any) => void;
};

const CheckBox = ({
	label,
	onKeyDown,
	checked = false,
	required,
	onChange,
	title,
	titleClassName = '',
	disabled,
	readOnly = false,
}: Props) => {
	const [_id] = useState(CreateRandomID());
	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!readOnly) {
			setIsChecked(event.target.checked);
			if (onChange) {
				onChange(event.target.checked);
			}
		}
	};

	return (
		<div>
			{label && (
				<div className="mb-2.5">
					<Label text={label} required={required} />
				</div>
			)}
			<label htmlFor={_id} className="flex cursor-pointer items-center gap-x-1.5">
				<input
					checked={!disabled && isChecked}
					onChange={handleChange}
					id={_id}
					type={'checkbox'}
					className={clsx('h-4 w-4 disabled:cursor-not-allowed')}
					disabled={disabled}
					onKeyDown={onKeyDown}
				/>
				<span className={`text-nowrap text-sm/none font-semibold text-black/50 ${titleClassName}`}>{title}</span>
			</label>
		</div>
	);
};

export default CheckBox;
