'use client';

import clsx from 'clsx';

type Props = {
	className?: string;
};
const HorizontalLine = (props: Props) => {
	return <hr className={clsx('h-[2px] border-black/10', props.className)} />;
};

export default HorizontalLine;
